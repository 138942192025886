@use "../../style/mixins";
@use "../../style/variables" as *;

.testimonials {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 2rem;
    color: $mainBlack;

    @include mixins.breakpoint-down(medium){
        row-gap: 2rem;
        justify-content: center;
    }

    &__info {
        flex: 1 1 20rem;

        & span {
            font-size: $fontSlg;
            font-weight: 700;
            color: $mainRed;
            text-transform: uppercase;
        }

        .testimonials__title {
            padding-top: 1rem;
            text-transform: capitalize;
        }

        .testimonials__description {
            font-size: $fontMd;
            line-height: 150%;
            padding: 1rem 10% 1rem 0;
        }
    }

    .testimonials__slider {
        flex: 1 1 40rem;
        padding: 2rem;
        position: relative;

        @include mixins.breakpoint-down(large){
            padding: 2rem 0 10% 0;
        }
        
        @include mixins.breakpoint-down(medium){
            padding: 2rem 0 15% 0;
        }

        &-wrapper {
            position: relative;
            overflow: hidden;
            height: 20rem;
            max-width: 30rem;
            margin: 0 auto;
            border-radius: .5rem;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

            @include mixins.breakpoint-down(small) {
                max-width: 35rem;
            }

            .slide {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: none;
                padding: 2rem 9%;
                transition: .3s;
                color: $mainBlack;

                @include mixins.breakpoint-down(medium){
                    padding: 2rem 9% 1rem 9%;
                }
    
                &.active-slide {
                    display: block;
                }
    
                & > * {
                    animation: slideScale .2s linear .2s backwards;
                }
    
                &__description {
                    font-size: $fontMd;
                    line-height: 170%;
                    margin-bottom: 3%;

                    @include mixins.breakpoint-down(medium) {
                        font-size: $fontSm;
                    }
                }
    
                .user {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
    
                    &__img {
                        height: 8rem;
                        width: 8rem;
                        object-fit: cover;
                        border-radius: .5rem;
                        margin-right: 1.5rem;
                    }
    
                    .user__name {
                        text-transform: capitalize;
                    }
    
                    & span {
                        color: $mainRed;
                        font-size: $fontMd;
                    }
    
                    .quote__icon {
                        margin-left: auto;
                        margin: 0 5% auto 5%;
                    }
                }
            }
    
            @keyframes slideScale {
                0% {
                    opacity: 0;
                    transform: scale(.5);
                }
            }
        }

        .prev__btn,
        .next__btn {
            position: absolute;
            top: 50%;
            background: transparent;
            cursor: pointer;
            filter: drop-shadow(.2rem .2rem .1rem #333);

            @include mixins.breakpoint-down(xlarge){
                top: 100%
            }
        }

        .prev__btn {
            left: 0;
            transform: translateY(-50%);

            @include mixins.breakpoint-down(large){
                left: 20%;
            }
        }

        .next__btn {
            right: 0;
            transform: translateY(-50%);
            @media screen and (max-width: 991px){
                right: 20%;
            }
        }
    }
}