@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500&display=swap');
// FONTS
$mainFont: "Nunito";
$fontXlg: 4rem;
$fontLg: 3rem;
$fontSlg: 1.5rem;
$fontMd: 1rem; //normal-font-size: 1rem;
$fontSm: .875rem; //small-font-size

// COLORS
$mainBlack:#333333;
$mainRed: #fa1a1a;
$mainWhite: #ffffff;
$lightGray: #aaaaaa;

// SHADOWS
$textSh: .15rem .15rem .2rem black;
$boxSh: 
rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, 
rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

// MARGINS
$mb-1: .5rem;
$mb-2: 1rem;
$mb-3: 1.5rem;
$mb-4: 2rem;
$mb-5: 2.5rem;
$mb-6: 3rem;

