@use "../../style/variables" as *;
@use "../../style/mixins";

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 9%;
    z-index: 1000;
    height: 9vh;
    background-color: $mainWhite;
    box-shadow: $boxSh;

    @include mixins.breakpoint-down(large){
        padding: 0 3%;
        height: 5rem;
    }

    &__logo {
        display: flex;
        align-items: center;
        column-gap: .5rem;
        font-weight: 700;
        color: $mainRed;
        font-size: 2rem;
        text-shadow: $textSh;
        text-transform: uppercase;
        margin: 1rem 0;
    }

    .menu__btn {
        display: none;
        margin: 1rem 0;
        cursor: pointer;

        @include mixins.breakpoint-down(medium){
            display: inline-block;

            .burger__btn.btn-active {
                transform: rotate(180deg) !important;
            }
        }
    }

    .navbar {
        display: flex;

        @include mixins.breakpoint-down(medium){
            display: block;
            position: absolute;
            top: 99%;
            left: 0;
            right: 0;
            background-color: $mainBlack;
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            transition: .3s;

            &.active {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            }
        }

        .navbar__link {
            display: inline-block;
            padding: 1.5rem 0 1.5rem 3rem;
            font-size: $fontMd;
            color: $mainBlack;
            font-weight: 700;
            text-transform: uppercase;
            transition: .3s;

            &:hover {
                color: $mainRed;
            }

            @include mixins.breakpoint-down(large){
                padding: 1.5rem 0 1.5rem 2rem;
            }
            @include mixins.breakpoint-down(medium){
                display: block;
                font-size: $fontMd;
                color: $mainWhite;
                padding: 2rem 0 2rem 2rem;
            }
        }
    }
}